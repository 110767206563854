import _ from 'lodash';
import { BarChartOutlined, DollarOutlined, ThunderboltOutlined } from '@ant-design/icons';

const getItem = (seq, label, key, icon, children) => {
  return { seq, label, key, icon, children };
};

// 篩選符合user.menu的item
export const filterMenus = (data, backendInfo) => {
  const tmpAry = _.cloneDeep(data);
  return tmpAry.filter((item) => {
    let matchedBackendItem = backendInfo.find((backendItem) => {
      return item.key === backendItem.menuPath;
    });

    if (matchedBackendItem) {
      item.label = matchedBackendItem.menuName; // 更新 label 屬性
    }

    if (item.children) {
      item.children = filterMenus(item.children, backendInfo);
    }

    return matchedBackendItem || (item.children && item.children.length > 0);
  });
};

export const MenuItems = [
  getItem('1', '專案預算管理', '/budget', <DollarOutlined />, [
    getItem('1-1', null, '/budgetAnalysisReport', null, null),
    getItem('1-2', null, '/budgetDetailReport', null, null), //
    getItem('1-3', null, '/budgetSubItemDocumentMapping', null, null), //
    getItem('1-4', null, '/budgetSubItemMaintenance', null, null), //
  ]), //
  getItem('2', null, '/dailyElectricityReport', <ThunderboltOutlined />, null), //
  getItem('3', null, '/projectReport', <BarChartOutlined />, null), //
];
