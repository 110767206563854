import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Badge, Layout, Dropdown } from 'antd';
import { SettingOutlined, PoweroffOutlined } from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import { getLogoutPath } from '../../utils/functionTool';
import logo_icon from '../../images/logo_icon.svg';

const { Header } = Layout;

const AppHeader = () => {
  const { user, UnAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    const logoutPath = getLogoutPath();

    UnAuthUser();
    if (logoutPath === '/login') {
      navigate('/login');
    } else {
      window.location.href = logoutPath;
    }
  };

  const userItems = [
    {
      key: 'environment',
      label: <span>環境設定</span>,
      icon: <SettingOutlined />,
      name: '環境設定',
      onClick: () => {
        navigate('/settings');
      },
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: <span>登出</span>,
      icon: <PoweroffOutlined />,
      name: '登出',
      onClick: () => {
        handleLogout();
      },
    },
  ];

  return (
    <Header className="app-header">
      <div className="app-header-container">
        <div className="app-header-left">
          <div className="app-header-logo">
            <Link to="/">
              <img src={logo_icon} alt="微電能源-ERP增強系統" />
            </Link>
            <span className="app-header-logo-text">ERP增強系統</span>
          </div>
        </div>

        <ul className="app-header-right">
          <li>
            <div className="user">
              <Dropdown placement="bottom" menu={{ items: userItems }} trigger={['click']}>
                <div>
                  <Badge size="small" count={0}>
                    <Avatar size="small">{user?.userName && user.userName[0]}</Avatar>
                  </Badge>
                  <span className="name">{user?.userName}</span>
                </div>
              </Dropdown>
            </div>
          </li>
        </ul>
      </div>
    </Header>
  );
};

export default AppHeader;
