import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import logo_s from '../../images/logo_s.svg';

const { Header } = Layout;

const AppHeader = () => {
  return (
    <Header className="app-simple-header">
      <div className="app-simple-logo">
        <Link to="/">
          <img src={logo_s} alt="微電能源-ERP增強系統" />
        </Link>
      </div>
    </Header>
  );
};

export default AppHeader;
