import React from 'react';
import { Link } from 'react-router-dom';
import {
  LoadingOutlined,
  FundViewOutlined,
  TableOutlined,
  BarChartOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { GetDecryptedString } from './encryption';

//時間區間
export const DEFAULT_TIMEZONE = '8';

//刷新時間
export const refetchInterval = 1000 * 60 * 5;

// loading spin
export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 55,
    }}
    spin
  />
);

export const getLogoutPath = () => {
  return GetDecryptedString(window.localStorage.getItem('logoutPath'));
};

//麵包屑計算路由
export const itemRender = (route, params, routes) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? <span>{route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>;
};

export const getTimelinePeriod = (timeline = 'day') => {
  if (timeline === 'day' || timeline === 'week') {
    return 'quarter';
  } else if (timeline === 'month') {
    return 'daily';
  } else if (timeline === 'year') {
    return 'monthly';
  } else {
    return 'yearly';
  }
};

export const getTimelinePeriodForTarget = (timeline = 'day') => {
  if (timeline === 'day' || timeline === 'week') {
    return 'daily';
  } else if (timeline === 'month') {
    return 'monthly';
  } else if (timeline === 'year') {
    return 'yearly';
  } else {
    return 'yearly';
  }
};

export const dateOptions = [
  {
    label: '天',
    value: 'day',
  },
  {
    label: '週',
    value: 'week',
  },
  {
    label: '月',
    value: 'month',
  },
  {
    label: '年',
    value: 'year',
  },
  {
    label: '累積至今',
    value: 'accumulatedToNow',
  },
];

//計算累積值
export const calculateCumulationValue = (data = [], key = 'value') => {
  if (data?.length === 0 || data === null) return 0;
  const cumulationValue = data?.reduce?.(
    (accumulator, currentValue) => accumulator + (currentValue?.[key] || 0),
    0
  );
  return Number(cumulationValue?.toFixed(2));
};

export const defaultAnalysisMenu = [
  {
    key: 'energyFlow',
    label: '能源流動',
    type: 'group',
    children: [
      {
        key: 'energyControl',
        icon: <FundViewOutlined />,
        label: '能源調度',
      },
      {
        key: 'energyReview',
        icon: <TableOutlined />,
        label: '能源使用總覽',
      },
      {
        key: 'contractStatus',
        icon: <TableOutlined />,
        label: '契約容量狀態',
      },
      {
        key: 'energyTarget',
        icon: <TableOutlined />,
        label: '能源調度指標',
      },
      {
        key: 'carbonEmissions',
        icon: <BarChartOutlined />,
        label: '減少的碳排放量',
      },
    ],
  },
  {
    key: 'energyDevice',
    label: '能源設備',
    type: 'group',
    children: [
      {
        key: 'solarMonitor',
        icon: <LineChartOutlined />,
        label: '太陽能設備',
      },
      {
        key: 'loadMonitor',
        icon: <LineChartOutlined />,
        label: '負載設備',
      },
      {
        key: 'essMonitor',
        icon: <LineChartOutlined />,
        label: '儲能設備',
      },
      {
        key: 'evseMonitor',
        icon: <LineChartOutlined />,
        label: '充電樁設備',
        name: '充電樁設備',
      },
      {
        key: 'windMonitor',
        icon: <LineChartOutlined />,
        label: '風能設備',
      },
    ],
  },
  {
    key: 'economyTarget',
    label: '經濟指標',
    type: 'group',
    children: [
      {
        key: 'greenBenefits',
        icon: <BarChartOutlined />,
        label: '綠電節電效益',
      },
      {
        key: 'essBenefits',
        icon: <BarChartOutlined />,
        label: '儲能節電效益',
      },
      {
        key: 'economyEstimate',
        icon: <BarChartOutlined />,
        label: '經濟效益預估',
      },
    ],
  },
];

//token過期時間 (30分)
export const expiresIn = 30 * 60 * 1000;

//編碼網址參數
export const codeParams = (data) => {
  let queryString = '';

  // 遍历数组，为每个对象构建查询字符串
  data.forEach((obj, index) => {
    Object.keys(obj).forEach((key) => {
      queryString += `conditions[${index}][${key}]=${encodeURIComponent(obj[key])}&`;
    });

    return queryString;
  });

  // 移除最后一个多余的"&"
  return queryString.slice(0, -1);
};

//解析網址參數
export const decodeParams = (queryString) => {
  // 解析查询字符串
  const params = new URLSearchParams(queryString);

  // 初始化结果数组
  const result = [];

  // 遍历所有参数
  for (const [key, value] of params) {
    // 使用正则表达式匹配索引和字段名
    const match = key.match(/conditions\[(\d+)\]\[(\w+)\]/);
    if (match) {
      const index = parseInt(match[1], 10);
      const field = match[2];

      // 确保数组长度
      while (index >= result.length) {
        result.push({});
      }

      // 添加值到对应的对象中
      result[index][field] = value;
    }
  }
  return result;
};

//清除使用者資訊
export const sessionClear = () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('logoutPath');
};

export const defaultCompanyOption = [
  {
    key: 'RFD_AA',
    label: 'RFD_AA-微電能源',
    value: 'RFD_AA',
  },
];
export const DEFAULT_COMPANY = 'RFD_AA';
