import React from 'react'
import { Layout } from 'antd'

const { Footer } = Layout
const date = new Date()

const AppFooter = (props) => {
    return (
        <Footer className="app-footer" {...props}>
            © {date.getFullYear()} Micro Electricity Co., Ltd. All rights
            reserved.
        </Footer>
    )
}

export default AppFooter
