import React from 'react';

const BudgetAnalysisReport = React.lazy(() => import('../pages/BudgetAnalysisReport'));
const DailyElectricityReport = React.lazy(() => import('../pages/DailyElectricityReport'));
const ProjectReport = React.lazy(() => import('../pages/ProjectReport'));
const BudgetDetailReport = React.lazy(() => import('../pages/BudgetDetailReport'));
const BudgetSubItemMaintenance = React.lazy(() => import('../pages/BudgetSubItemMaintenance'));
const BudgetSubItemDocumentMapping = React.lazy(() => import('../pages/BudgetSubItemDocumentMapping'));

//路由
export const RouteItems = [
  { path: '/budgetAnalysisReport', element: <BudgetAnalysisReport />, breadcrumbName: '預算分析報表' },
  { path: '/dailyElectricityReport', element: <DailyElectricityReport />, breadcrumbName: '每日發電量報表' },
  { path: '/projectReport', element: <ProjectReport />, breadcrumbName: '專案代號查詢' },
  { path: '/budgetDetailReport', element: <BudgetDetailReport />, breadcrumbName: '預算明細報表' },
  {
    path: '/budgetSubItemMaintenance',
    element: <BudgetSubItemMaintenance />,
    breadcrumbName: '預算子項目維護',
  },
  {
    path: '/budgetSubItemDocumentMapping',
    element: <BudgetSubItemDocumentMapping />,
    breadcrumbName: '預算子項目文件對應',
  },
];
