import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Spin, Typography, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import SimpleHeader from '../../components/SimpleHeader';
import Footer from '../../components/Footer';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidApiGatewayRsps } from '../../utils/valid';

const { Content } = Layout;
const { Text } = Typography;
const LoadingIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);
const Auth = () => {
  const { AuthUser } = useContext(AuthContext);
  const [previousPage, setPreviousPage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const referrer = document.referrer;
    const currentOrigin = window.location.origin;

    if (referrer && referrer.startsWith(currentOrigin)) {
      setPreviousPage('/login');
    } else {
      setPreviousPage(referrer);
    }
  }, []);

  useEffect(() => {
    if (!previousPage) return;

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const otp = params.get('otp');
    if (code || otp) {
      const postData = code
        ? { systemID: appConfigs.systemID, grantCode: code }
        : { systemID: appConfigs.systemID, otpID: otp };

      axios
        .post(`${appConfigs.apiGatewayLoginURL}`, postData)
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            notification.warning({
              message: res.data?.msg,
              placement: 'top',
              key: res.data?.msg,
            });
            if (previousPage === '/login') {
              navigate('/login');
            } else {
              window.location.href = previousPage;
            }
          }

          AuthUser({
            user: {
              userName: res.data.data.userName,
              menus: res.data.data.menuList || [],
              operations: res.data.data.operationList || [],
              otpID: res.data.data.otpID || '',
            },
            token: {
              access_token: res.data.data.token,
              token_type: 'bearer',
            },
            logoutPath: previousPage,
          });
          navigate('/');
        })
        .catch((error) => {
          notification.warning({
            message: error.toString(),
            placement: 'top',
            key: error.toString(),
          });
          if (previousPage === '/login') {
            navigate('/login');
          } else {
            window.location.href = previousPage;
          }
        });
    } else {
      notification.warning({
        message: '無法取得登入驗證資訊',
        placement: 'top',
        key: '無法取得登入驗證資訊',
      });

      if (previousPage === '/login') {
        navigate('/login');
      } else {
        window.location.href = previousPage;
      }
    }
  }, [AuthUser, navigate, previousPage]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <SimpleHeader />
      <Content className="app-layout-content">
        <div className="app-page-body">
          <Row
            justify="center"
            align="middle"
            gutter={[
              { xs: 16, sm: 16, md: 16, lg: 16 },
              { xs: 16, sm: 16, md: 16, lg: 16 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 'calc(100vh - 194px)',
                }}
              >
                <div>
                  <Spin indicator={LoadingIcon} />
                  <Text style={{ display: 'block', marginTop: 16 }}>載入中</Text>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Auth;
