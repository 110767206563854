import React, { useContext, useEffect, useState } from 'react';
import useRWD from '../../hooks/useRWD';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Checkbox, message } from 'antd';
import {
  ToolOutlined,
  ApartmentOutlined,
  FundViewOutlined,
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { GetEncryptedString, GetDecryptedString } from '../../utils/encryption';
import useLocalStorage from '../../hooks/useLocalStorage';
import { IsValidApiGatewayRsps } from '../../utils/valid';
import bg from '../../images/loginbg.jpg';
import bg_m from '../../images/loginbg_m.jpg';
import logo_w from '../../images/logo_w.svg';
import google_logo from '../../images/google_logo.svg';

const Login = ({ homePagePath }) => {
  const { user, AuthUser } = useContext(AuthContext);
  const [email, setEmail] = useLocalStorage('email', '');
  const [password, setPassword] = useLocalStorage('password', '');
  const [rememberAccount, setRememberAccount] = useLocalStorage('remember', 'false');
  const [loading, setLoading] = useState(false);
  const device = useRWD();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }

    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        const data = event.data;
        if (data.token) {
          AuthUser(data);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [user, navigate, AuthUser]);

  const handleGoogleLogin = () => {
    axios
      .get(appConfigs.apiGatewayLoginURL)
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          console.log('login fail', res.data.msg);
          return;
        }

        const redirectUrl = res.data.data || '';
        if (redirectUrl) window.location.href = redirectUrl;
      })
      .catch((err) => {
        console.error('login error', err);
      });
  };

  const handleLogin = (values) => {
    const username = values.username.trim();
    const password = values.password.trim();
    if (!username || !password) {
      message.warning('請輸入帳號/密碼');
      return;
    }

    if (values.remember) {
      setEmail(GetEncryptedString(username));
      setPassword(GetEncryptedString(password));
      setRememberAccount(values.remember);
    } else {
      setEmail('');
      setPassword('');
      setRememberAccount('');
    }

    setLoading(true);
    axios
      .post(appConfigs.apiGatewayLoginURL, {
        systemID: appConfigs.systemID,
        account: username,
        password: password,
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.error('登入失敗，請重新輸入帳號與密碼');
          console.warn('login fail', res);
          return;
        }

        if (!res.data.data.token) {
          message.error('登入失敗，無法取得授權金鑰');
          console.warn('login fail', res);
          return;
        }

        AuthUser({
          user: {
            email: username,
            userName: res.data.data.userName,
            menus: res.data.data.menuList || [],
            operations: res.data.data.operationList || [],
            otpID: res.data.data.otpID || '',
          },
          token: {
            access_token: res.data.data.token,
            token_type: 'bearer',
          },
          logoutPath: '/login',
        });

        navigate('/');
      })
      .catch((err) => {
        message.error('登入異常');
        console.error('login error', err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="app-login"
      style={
        device !== 'hisesPC' && device !== 'PC'
          ? { backgroundImage: `url(${bg_m})` }
          : { backgroundImage: `url(${bg})` }
      }
    >
      <div className="app-login-header">
        <div className="container">
          <div className="app-login-header-menu">
            <div className="logo">
              <img src={logo_w} alt="微電能源-ERP增強系統" />
            </div>
            <div className="menu">
              <ol>
                <li>
                  <Button
                    type="link"
                    href="https://www.rfdme.com"
                    target="_blank"
                    icon={<ApartmentOutlined />}
                  >
                    微電官網
                  </Button>
                </li>
                <li>
                  <Button type="link" href="https://ems.rfdme.io" target="_blank" icon={<FundViewOutlined />}>
                    微電能源管理系統
                  </Button>
                </li>
                <li>
                  <Button type="link" href="https://om.rfdme.io" target="_blank" icon={<ToolOutlined />}>
                    微電維運系統
                  </Button>
                </li>
              </ol>
            </div>
            <div className="btn">
              <Button href="mailto:jack.yang@rfdme.com?subject=ERP增強系統問題回報" ghost>
                問題回報
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="app-login-content">
        <div className="container">
          <div className="app-login-form">
            <h1>微電ERP增強系統</h1>
            {process.env.REACT_APP_STAGE_NAME !== '正式區' && (
              <div className="stage-name">{process.env.REACT_APP_STAGE_NAME}</div>
            )}
            {(process.env.REACT_APP_STAGE_NAME || process.env.REACT_APP_STAGE_NAME !== '正式區') && (
              <Form
                name="login"
                layout="vertical"
                onFinish={handleLogin}
                initialValues={{
                  remember: rememberAccount,
                  username: GetDecryptedString(email),
                  password: GetDecryptedString(password),
                }}
                size="large"
                autoComplete="off"
                onFieldsChange={(changedFields) => {
                  if (changedFields[0].name[0] === 'remember') setRememberAccount((value) => !value);
                }}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>記住我</Checkbox>
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading} block>
                    登入
                  </Button>
                </Form.Item>
              </Form>
            )}
            <Button
              onClick={handleGoogleLogin}
              icon={<img alt="google" width="14" height="14" src={google_logo} />}
              size="large"
              ghost
              block
            >
              用Google SSO登入
            </Button>
          </div>
        </div>
      </div>
      <div className="app-login-footer">
        <div className="container">
          <p>©{moment().year()} Micro Electricity Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
