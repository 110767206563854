import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import { Statistic, notification } from 'antd';
import App from './layouts/app';
import { AuthContextProvider } from './contexts/authProvider';
import { SettingContextProvider } from './contexts/settingProvider';
import axios from 'axios';
import { sessionClear, getLogoutPath } from './utils/functionTool';
import 'antd/dist/reset.css';
import './css/main.css';
import './css/dark.css';
import './css/rwd.css';

const { Countdown } = Statistic;
//
let abortControllers = new Map();
let isLoggedOut = false;

const getRequestIdentifier = (config) => {
  let data = config.data;
  if (config.params && Object.keys(config.params).length) {
    data = { ...data, ...config.params };
  }
  // Create a stringified key using both the URL and the serialized data
  return `${config.url}_${JSON.stringify(data)}`;
};

const removePendingRequest = (config, abort = false) => {
  // Generate the request identifier
  const requestIdentifier = getRequestIdentifier(config);
  if (abortControllers.get(requestIdentifier)) {
    if (abort) {
      // Abort the request if needed
      abortControllers.get(requestIdentifier).abort();
    }
    // Remove the request identifier from the map
    abortControllers.delete(requestIdentifier);
  }
};

const logout = () => {
  if (isLoggedOut) return;

  notification.warning({
    message: (
      <div>
        登入逾時，系統將於
        <Countdown
          value={Date.now() + 3 * 1000}
          format="s"
          onFinish={() => (window.location.href = getLogoutPath() || '/login')}
          style={{ display: 'inline-block', padding: '0 2px' }}
          className="logout-count-down"
        />
        秒後自動登出，請再次登入
      </div>
    ),
    placement: 'top',
    key: '401',
  });
  sessionClear();
};

axios.interceptors.request.use(
  (config) => {
    //
    removePendingRequest(config, true);
    const controller = new AbortController();
    config.signal = controller.signal;
    abortControllers.set(getRequestIdentifier(config), controller);

    const tokenInHeader = (((config || { headers: {} }).headers || {}).Authorization || '')
      .replace('Bearer', '')
      .trim();
    if (!tokenInHeader) return config;

    const tokenInStorage = window.localStorage.getItem('token');
    if (!tokenInStorage) {
      logout();
      return Promise.reject('token was removed');
    }

    const expTimestamp = (jwtDecode(tokenInHeader) || {}).exp || 1;
    if (!dayjs().isBefore(expTimestamp * 1000)) {
      logout();
      return Promise.reject(new axios.Cancel('token has expired.'));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    abortControllers.delete(response.config?.url);
    return response;
  },
  (error) => {
    abortControllers.delete(error.config?.url);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          const detail = error?.response?.data?.detail;
          if (detail?.includes('pageToken')) {
            console.warn('pageToken error');
          } else if (detail?.includes('Not Authenticated!')) {
            console.warn('Not Authenticated!');
            logout();
          } else {
            console.warn('Google Drive API error');
            notification.warning({
              message: <div>無效的請求或無權限</div>,
              placement: 'top',
              key: '400',
            });
          }
          break;
        case 401:
          console.warn('端點請求超時或無權限');
          //logout();
          break;
        case 403:
          console.warn('沒有存取權限');
          notification.warning({
            message: <div>沒有存取權限，無權限使用此動作，如有疑問請通知系統管理員確認權限</div>,
            placement: 'top',
            key: '403',
          });
          break;
        case 404:
          console.warn('找不到該頁面');
          notification.warning({
            message: <div>找不到該頁面</div>,
            placement: 'top',
            key: '403',
          });
          break;
        case 500:
          const errorDetails = error.response.data.detail || '';
          const isPageTokenError = errorDetails?.includes('pageToken');
          if (isPageTokenError) {
            console.warn('pageToken error');
          } else {
            console.warn('伺服器出錯');
            notification.warning({
              message: <div>伺服器出錯</div>,
              placement: 'top',
              key: '500',
            });
          }
          break;
        case 503:
          console.warn('服務失效');
          notification.warning({
            message: <div>服務失效</div>,
            placement: 'top',
            key: '503',
          });
          break;
        case 504:
          console.warn('伺服器逾時');
          notification.warning({
            message: <div>伺服器逾時，請重新嘗試</div>,
            placement: 'top',
            key: '504',
          });
          break;
        default:
          console.warn(`連接錯誤: ${error.response.status}`);
      }
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <SettingContextProvider>
        <App />
      </SettingContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
);
