import React, { useState } from 'react';
import {
  GetEncryptedString,
  GetDecryptedString,
  GetEncryptObject,
  GetDecryptedObject,
} from '../utils/encryption';
import { sessionClear } from '../utils/functionTool';

//Auth 共享文本
const AuthContext = React.createContext();

//
const GetInitUser = () => {
  const user = GetDecryptedObject(window.localStorage.getItem('user'));
  if (user) {
    return user;
  } else {
    return null;
  }
};

const GetInitToken = () => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  if (token) {
    return token;
  } else {
    return null;
  }
};

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(GetInitUser());
  const [token, setToken] = useState(GetInitToken());

  const AuthUser = (authUser) => {
    if (authUser) {
      const token = `Bearer ${authUser.token.access_token}`;
      window.localStorage.setItem('user', GetEncryptObject(authUser.user));
      window.localStorage.setItem('token', GetEncryptedString(token));
      window.localStorage.setItem('logoutPath', GetEncryptedString(authUser.logoutPath));
      setUser(authUser.user);
      setToken(token);
    }
  };
  const UnAuthUser = () => {
    sessionClear();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, token, AuthUser, UnAuthUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
