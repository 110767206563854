import React, { Suspense, useContext, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthContext from '../contexts/authProvider';
import SettingContext from '../contexts/settingProvider';
import Login from '../pages/Login';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Auth from '../pages/Auth';
import { MenuItems, filterMenus } from './menus';
import { RouteItems } from './routes';

const NotFound = React.lazy(() => import('../pages/NotFound'));
const Settings = React.lazy(() => import('../pages/Settings'));

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);

const NavWrapper = () => {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [routes, setRoutes] = useState([]);
  const [initialRedirect, setInitialRedirect] = useState(true); // 新增狀態追踪初次重定向
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user || !user.menus) return;

    const authRouteList = filterMenus(MenuItems, user.menus)
      .sort((a, b) => a.seq.localeCompare(b.seq)) // 根據 seq 排序
      .flatMap((item) => (item.children ? item.children : [item])) // 展開子菜單
      .map((item) => ({
        path: item.key,
        element: RouteItems.find((route) => route.path === item.key)?.element,
      }));
    // 增加首頁
    if (authRouteList.length > 0) {
      authRouteList.push({ path: '/', element: authRouteList[0].element });
    } else {
      authRouteList.push({ path: '/', element: <></> });
    }
    setRoutes(authRouteList);

    if (initialRedirect) {
      const currentPath = location.pathname;
      if (currentPath === '/' || !authRouteList.some((route) => route.path === currentPath)) {
        navigate(authRouteList[0].path);
      }
      setInitialRedirect(false);
    }
  }, [user, navigate, initialRedirect, location.pathname]);

  if (!user) return <Navigate to="/login" />;

  return (
    <Layout className={`app-layout ${dark ? 'dark' : ''}`}>
      <Header />
      <Layout>
        <Sidebar />
        <Layout>
          <Suspense
            fallback={
              <div className="app-content loading" style={{ width: '100%' }}>
                <Spin indicator={antIcon} />
              </div>
            }
          >
            <Routes>
              {routes.map((r, rIdx) => (
                <Route key={`route-${rIdx}`} path={r.path} element={r.element} />
              ))}
              <Route path="/settings" element={<Settings />} /> {/* 對所有登入者開放 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Outlet />
          </Suspense>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/oauth" element={<Auth />} />
        <Route path="/*" element={<NavWrapper />} />
      </Routes>
    </Router>
  );
};

export default App;
