import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import { MenuItems, filterMenus } from '../../layouts/menus';
const { Sider } = Layout;

const searchKey = (data, targetKey) => {
  let results = [];

  for (let item of data) {
    if (item.key === targetKey) {
      results.push(item);
    }

    if (item.children) {
      results = results.concat(searchKey(item.children, targetKey));
    }
  }

  return results;
};

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const [menus, setMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.menus) return;

    if (Array.isArray(user.menus) && user.menus.length > 0) {
      const filterMenu = filterMenus(MenuItems, user.menus);
      setMenus(filterMenu);
    }
  }, [user]);

  const currentSelectedMenuKey = useMemo(() => {
    if (location.pathname === '/') return '/';

    const pathSnippets = location.pathname.split('/').filter((i) => i);

    let menuKey = '';
    for (let i = pathSnippets.length; i > 0; i--) {
      const url = `/${pathSnippets.slice(0, i).join('/')}`;
      const matchMenu = searchKey(MenuItems, url);
      if (matchMenu.length) {
        menuKey = matchMenu[0].key;
        break;
      }
    }

    return menuKey;
  }, [location]);

  const handleMenuSelected = ({ key, domEvent }) => {
    const menuItem = domEvent.target.closest('.ant-menu-item');
    const label = menuItem ? menuItem.textContent : '';
    navigate(key, { state: { label: label } });
  };

  return (
    <Sider
      collapsible
      className="app-aside"
      collapsed={collapsed}
      collapsedWidth={60}
      onCollapse={(value) => setCollapsed(value)}
      trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
    >
      <Menu
        mode="inline"
        className="app-aside-menu"
        inlineIndent={16}
        selectedKeys={[currentSelectedMenuKey]}
        onSelect={handleMenuSelected}
        items={menus}
      />
    </Sider>
  );
};

export default Sidebar;
