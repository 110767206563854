import { AES, enc } from 'crypto-js';
import getFingerprint from './fingerprint';
import { IsNullOrUndefined } from './valid';

const secureKey = getFingerprint().toString();

export const GetEncryptedString = (val) => {
  if (IsNullOrUndefined(val)) return null;

  return AES.encrypt(val, secureKey).toString();
};

export const GetDecryptedString = (val) => {
  if (IsNullOrUndefined(val)) return null;

  try {
    return AES.decrypt(val, secureKey).toString(enc.Utf8);
  } catch (ex) {
    return null;
  }
};

export const GetEncryptObject = (obj) => {
  if (IsNullOrUndefined(obj)) return null;

  return AES.encrypt(JSON.stringify(obj), secureKey).toString();
};

export const GetDecryptedObject = (val) => {
  if (IsNullOrUndefined(val)) return null;

  try {
    const decryptedObject = AES.decrypt(val, secureKey).toString(enc.Utf8);
    return decryptedObject ? JSON.parse(decryptedObject) : null;
  } catch (ex) {
    return null;
  }
};
